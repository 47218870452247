$c_primary: #081630;
$c_secondary: #56bb6d;
$head_color:#202020;
$body_color:#393939;
$c_primary_hover: #d52057;
$c_primary_text: #051140;
$c_text: #081630;
$c_form_border: #ff92a5;
$c_white: #fff;
$c_black: #000; 
$danger:#D8502C;
$c_red: #ff0000;
$c_red_hover: #c20303;
$c_text_grey: #999;
$c_text_grey2:#A5A5A5;
$c_text_grey3:#C7C7C7;

$shadow: rgba(0, 0, 0, 0.08) 0px 2px 25px 0px;
$shadow2: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
$transition: all 0.3s ease-in-out 0s;
$border_color:  #C2C2C2;
$light_gray:#f5f5f5;
$f_body:"Urbanist", sans-serif;

@mixin d-flex($align:center, $justy:space-between,$gap:0,$wrap:wrap){
    display:flex;
    align-items:$align;
    justify-content: $justy;
    gap:$gap;
    flex-wrap: $wrap;
} 

@mixin box_shadow($padding:0 0,$radius:0){
    background: $c_white;
    box-shadow: 0px 4px 4px #0000000A;
    padding: $padding;
    border-radius: $radius;
}
@mixin box_shadow2($padding:0 0,$radius:0){
    background: $c_white;
    box-shadow: 0px 13px 25px #00000012;
    padding: $padding;
    border-radius: $radius;
}

@mixin img_contain($object:contain){
    width: 100%;
    height: 100%;
    object-fit: $object;
    // border-radius: 100%;
}

// Media queries
@mixin breakpoint($point) {
    @if $point==desktopLarge {
        @media only screen and (min-width: 1400px) {
            @content;
        }
    }
    @else if $point==desktop {
        @media only screen and (max-width: 1399.98px) {
            @content;
        }
    }
    @else if $point==ipadLandsacpe {
        @media only screen and (max-width: 1199.98px) {
            @content;
        }
    }
    @else if $point==ipad {
        @media only screen and (max-width: 991.98px) {
            @content;
        }
    }
    @else if $point==mobile {
        @media only screen and (max-width: 767.98px) {
            @content;
        }
    }
    @else if $point==mobileSmall {
        @media only screen and (max-width: 575.98px) {
            @content;
        }
    }
    @else if $point==ipadLarge {
        @media only screen and (min-width: 768px) {
            @content;
        }
    }
}