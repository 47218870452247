$c_primary: #081630;
$c_secondary: #56bb6d;

$c_primary_hover: #d52057;
$c_primary_text: #051140;
$c_form_border: #ff92a5;
$c_white: #fff;
$c_black: #000;
$c_red: #ff0000;
$c_red_hover: #c20303;
$c_text_grey: #999;
$shadow: rgba(0, 0, 0, 0.08) 0px 2px 25px 0px;
$shadow2: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
$transition: all 0.3s ease-in-out 0s;

@import '../variable.scss';

.dashboard_main.mn-lyout {
  margin: 50px 0 50px auto;
}

.main_bx {
  width: 100%;
}

.dashboard_main.mn-lyout {
  margin-top: 50px;
}

// header css
.site_header {
  width: 100%;
  transition: $transition;
  z-index: 99;

  &.scrolled {
    +.main_header {
      box-shadow: 0px 14px 64px -4px rgba(45, 115, 227, 0.12), 0px 8px 22px -6px rgba(45, 115, 227, 0.12);
      ;
    }
  }
}

.site_logo {
  display: inline-flex;
  margin-right: auto;
  figure {
    display: inline-flex;

    img {
      height: 30px;
      @include breakpoint(mobileSmall){
        max-width: 140px;
        height: auto;
      }
    }
  }
}

.social_icons {
  display: inline-flex;
  align-items: center;

  li {
    margin: 0;
    display: inline-flex;

    a {
      display: inline-flex;
    }
  }
}

.top_header {
  padding: 10px 0;
  background-color: $c_white;

  .d_flex {
    display: flex;
    align-items: center;
    gap: 30px;
    @include breakpoint(mobile){
      justify-content: space-between;
      > p {
        display: none;
      }
    }
  }

  p {
    font-size: 22px;
    font-weight: 600;
    text-transform: capitalize;
    margin: 0;
    @include breakpoint(ipad){
      font-size: 16px;
    }
  }

  .social_icons {
    gap: 20px;
    margin: 0 0 0 auto;
    @include breakpoint(mobile){
      margin: 0;
    }
    @include breakpoint(mobileSmall){
      gap: 14px;
    }
  }

  .btn {
    font-size: 16px;
    min-height: 40px;
    padding: 2px 10px;
    min-width: 1px;
    @include breakpoint(ipad){
      font-size: 14px;
    
  }
    @include breakpoint(mobileSmall){
        font-size: 13px;
        min-height: 36px;
    }
  }
}

.main_header {
  padding: 10px 0;
  background-color: $c_primary;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;

  .d_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .site_hamburgur {
    display: none;
    color: $c_white;
    height: 40px;
    width: 40px;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 5px;
    margin-right: 25px;
    @include breakpoint(ipadLandsacpe) {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    @include breakpoint(mobile) {
      height: 30px;
      width: 30px;
      border-radius: 2px;
      margin-right: 15px;
    }
    @include breakpoint(mobileSmall){
      height: 24px;
      width: 24px;
      min-width: 24px;
      border-radius: 2px;
      margin-right: 8px;
    }
    svg {
      font-size: 24px;
      @include breakpoint(mobile) {
        font-size: 20px;
      }
    }
    &.active {
      color: #FF0000;
      background-color: $c_white;
    }
  }

  .site_menu {
    display: inline-flex;
    align-items: center;
    margin: 0 65px 0 auto;

    li {
      margin: 0;

      &:not(:first-child) {
        margin-left: 35px;
      }

      a {
        color: $c_white;
        font-size: 16px;
        font-weight: 500;
        font-family: $f_body;
        text-transform: capitalize;
        transition: $transition;
        letter-spacing: 0;
        line-height: 1.4;
        padding: 0;

        svg {
          font-size: 20px;
        }

        &:hover {
          color: $c_secondary;
        }
      }
    }
  }

  .site_action {
    display: inline-flex;
    align-items: center;
    gap: 20px;
    @include breakpoint(mobileSmall){
      gap: 0px;
    }

    .btn {
      min-height: 50px;
      min-width: 146px;
      font-size: 20px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  .profile_btn {
    a {
      color: $c_white;
      font-size: 14px;
      font-weight: 700;
      display: flex;
      align-items: center;
      font-family: $f_body;
      text-transform: capitalize;
      transition: $transition;
      letter-spacing: 0;
      line-height: 1.4;
      padding: 0;
      gap: 10px;

      span {
        display: inline-block;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include breakpoint(mobileSmall){
          display: none;
        }
      }

      figure {
        display: inline-flex;

        img {
          width: 50px;
          height: 50px;
          object-fit: cover;
          object-position: center;
          border-radius: 50%;
          @include breakpoint(mobileSmall){
            width: 30px;
            height: 30px;
          }
        }
      }

      svg {
        margin-left: -10px;
        font-size: 20px;
      }

      &:hover {
        color: $c_secondary;
      }
    }
  }

  .btn_icon {
    padding: 0;
    min-width: 1px;
    letter-spacing: 0;
    border: none;
    box-shadow: none;

    img {
      height: 38px;
      @include breakpoint(mobileSmall){
        height: 25px;
      }
    }
  }
}

.menu_dropdown {
  .MuiPaper-root {
    padding-top: 21px;
    box-shadow: 0px 2px 16px #00000029;
    background-color: transparent;

    &::before {
      content: "";
      position: absolute;
      left: 12px;
      top: 15px;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid $c_white;
    }
  }

  ul {
    padding: 10px 0;
    min-width: 150px;
    background-color: $c_white;

    li {
      display: flex;
      margin: 0;

      a {
        width: 100%;
        display: flex;
        color: $c_text;
        font-size: 14px;
        font-weight: 600;
        padding: 4px 12px;
        transition: $transition;

        &:hover {
          color: $c_white;
          background-color: $c_secondary;
        }
      }
    }
  }

  &User {
    .MuiPaper-root {
      padding-top: 10px;

      &::before {
        top: 4px;
      }
    }
  }
}

.site_footer {
  background-color: $c_primary;
}




.main_footer {
  padding: 75px 0 40px;
  display: flex;
  justify-content: space-between;
  gap: 30px;

  @include breakpoint(ipadLandsacpe) {
    padding: 60px 0 40px;
    gap: 20px;
  }

  @include breakpoint(ipad) {
    flex-wrap: wrap;
    padding: 50px 0 40px;
    gap: 45px 20px;
  }

  @include breakpoint(mobile) {
    padding: 40px 0;
    gap: 35px 10px;
  }

  .single {
    max-width: 290px;

    @include breakpoint(ipadLandsacpe) {
      max-width: 240px;
    }

    @include breakpoint(mobile) {
      width: 100%;
      max-width: 100%;
    }

    &:first-child {
      max-width: 250px;

      @include breakpoint(ipadLandsacpe) {
        max-width: 220px;
      }

      @include breakpoint(ipad) {
        width: 100%;
        max-width: 100%;
      }
    }

    &:last-child {
      width: 100%;
    }

    .site_logo {
      margin-bottom: 15px;

      +p {
        @include breakpoint(ipadLandsacpe) {
          font-size: 14px;
          margin-bottom: 0;
        }
      }
    }

    :where(h2, p, li, a) {
      color: $c_white;
    }

    h2 {
      margin-bottom: 30px;

      @include breakpoint(ipadLandsacpe) {
        font-size: 18px;
        padding-top: 5px;
        margin-bottom: 24px;
      }

      @include breakpoint(mobile) {
        padding-top: 0;
        margin-bottom: 18px;
      }
    }

    ul:not(.social_icons) {
      li {
        display: flex;

        @include breakpoint(mobile) {
          &:not(:last-child) {
            margin-bottom: 12px;
          }
        }

        a {
          font-size: 16px;
          font-weight: 400;
          display: inline-flex;
          line-height: 1.2;

          @include breakpoint(ipadLandsacpe) {
            font-size: 14px;
          }

          &:hover {
            color: $c_secondary;
          }
        }
      }
    }

    .social_icons {
      display: flex;
      margin-top: 20px;

      @include breakpoint(mobile) {
        margin-top: 15px;
      }

      li {
        margin: 0 10px 0 0;

        a {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background-color: $c_white;
          display: inline-flex;
          align-items: center;
          justify-content: center;

          @include breakpoint(ipad) {
            width: 35px;
            height: 35px;
          }

          img {
            max-width: 17px;
            max-height: 17px;
          }
        }
      }
    }
  }

  .form {
    &_control {
      margin-bottom: 15px;

      @include breakpoint(ipadLandsacpe) {
        margin-bottom: 10px;
      }

      input {
        height: 40px;
        border-radius: 10px;

        @include breakpoint(ipadLandsacpe) {
          border-radius: 5px;
          padding: 5px 10px;
          font-size: 14px;
        }
      }

      p {
        color: #909090;
        font-size: 14px;
        font-weight: 400;

        @include breakpoint(ipadLandsacpe) {
          font-size: 12px;
        }

        a {
          color: $c_secondary;
          text-decoration: underline;
        }
      }
    }

    &_btn {
      margin-top: 20px;
      text-align: right;

      .btn {
        min-height: 40px;
        min-width: 130px;
        border-radius: 10px;
        font-size: 14px;
        font-weight: 600;
        width: auto;
      }
    }
  }
}

.copywrite_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #5C5C5C;
  padding: 20px 0;
  gap: 30px;

  @include breakpoint(mobile) {
    text-align: center;
    flex-direction: column;
    gap: 15px 0;
  }

  p {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: $c_white;

    @include breakpoint(ipadLandsacpe) {
      font-size: 14px;
    }

    &:first-child {
      @include breakpoint(mobile) {
        max-width: 240px;
      }
    }
  }
}


.pls_icn {
  background: #56bb6d;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.emp_lst {
  margin-bottom: 0 !important;

  li {
    width: calc(100% / 3 - var(--gap_x));
    gap: 15px;
    display: flex;
    align-items: center;
    background: $c_white 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 4px #0000000a;
    opacity: 1;
    padding: 20px;
    border-radius: 15px;
    margin-bottom: 0;
    position: relative;
    @include breakpoint(ipad){
      width: calc(100% / 2 - var(--gap_x));
      padding: 15px;
    }
    @include breakpoint(mobileSmall){
      width: calc(100%  - var(--gap_x));
     
    }
    .pos_delete {
      position: absolute;
      right: 5px;
      top: 5px;
      padding: 0;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #ff0000;
      color: #ff0000;
      transition: $transition;

      svg {
        font-size: 14px;
      }
      
      &:hover {
        color: $c_white;
        background-color: #ff0000;
      }
    }

    >figure {
      width: 73px;
      height: 73px;
      min-width: 73px;
      border-radius: 50%;
      position: relative;
      background-color: #f9f9f9;
      @include breakpoint(mobile){
        width: 45px;
        height: 45px;
        min-width: 45px;
      }
      @include breakpoint(mobileSmall){
        width: 40px;
        height: 40px;
        min-width: 40px;
      }
      img {
        @include img_contain(cover);
        border-radius: 50%;
      }

    }

    .usr_cnt {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: calc(100% - 88px);

      &:only-child {
        width: 100%;
      }
      .btn_group{
        display: flex;
        gap: 15px;
      }
    }

    h3 {
      font-size: 14px;
      color: #202020;
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
    }

    p {
      font-size: 12px;
      color: #a5a5a5;
      margin: 5px 0 10px;
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
    }

    .lst_btn {
      background: #56bb6d 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
      border: none;
      color: #fff;
      font-size: 12px;
      padding: 5px;
    }

    &.active {
      >figure {
        &::before {
          content: "";
          position: absolute;
          inset: auto 0px 3px auto;
          background: #56bb6d;
          border: 4px solid #fff;
          width: 15px;
          height: 15px;
          border-radius: 15px;
          box-sizing: border-box;
          @include breakpoint(mobile){
            inset: auto -2px 5px auto;

    border: 2px solid #fff;
    width: 10px;
    height: 10px;
          }
        }
      }
    }
  }

  &.userList {
    li {
      >figure {
        margin-bottom: 23px;
      }

      .MuiSwitch-root {
        height: 18px;
        width: 30px;
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translate(-50%, 100%);

        .MuiSwitch-switchBase {
          height: 18px;
          left: 2px;

          .MuiSwitch-thumb {
            width: 14px;
            height: 14px;
          }

          &.Mui-checked {
            left: -1px;
          }
        }
      }
    }
  }
}