@import "./variable.scss";
@import "./style.scss";
$c_primary: #081630;

$c_primary_hover: #d52057;
$c_primary_text: #051140;
$c_form_border: #ff92a5;

$c_red: #ff0000;
$c_red_hover: #c20303;

$shadow: rgba(0, 0, 0, 0.08) 0px 2px 25px 0px;
$shadow2: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

// fonts family

@font-face {
  font-family: "Urbanist";
  src: url("../public/fonts/Urbanist-BlackItalic.woff2") format("woff2"),
    url("../public/fonts/Urbanist-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist";
  src: url("../public/fonts/Urbanist-Black.woff2") format("woff2"),
    url("../public/fonts/Urbanist-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist";
  src: url("../public/fonts/Urbanist-ExtraLightItalic.woff2") format("woff2"),
    url("../public/fonts/Urbanist-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist";
  src: url("../public/fonts/Urbanist-ExtraLight.woff2") format("woff2"),
    url("../public/fonts/Urbanist-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist";
  src: url("../public/fonts/Urbanist-Bold.woff2") format("woff2"),
    url("../public/fonts/Urbanist-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist";
  src: url("../public/fonts/Urbanist-Italic.woff2") format("woff2"),
    url("../public/fonts/Urbanist-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist";
  src: url("../public/fonts/Urbanist-Light.woff2") format("woff2"),
    url("../public/fonts/Urbanist-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist";
  src: url("../public/fonts/Urbanist-ExtraBoldItalic.woff2") format("woff2"),
    url("../public/fonts/Urbanist-ExtraBoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist";
  src: url("../public/fonts/Urbanist-ExtraBold.woff2") format("woff2"),
    url("../public/fonts/Urbanist-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist";
  src: url("../public/fonts/Urbanist-BoldItalic.woff2") format("woff2"),
    url("../public/fonts/Urbanist-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist";
  src: url("../public/fonts/Urbanist-Medium.woff2") format("woff2"),
    url("../public/fonts/Urbanist-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist";
  src: url("../public/fonts/Urbanist-SemiBold.woff2") format("woff2"),
    url("../public/fonts/Urbanist-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist";
  src: url("../public/fonts/Urbanist-SemiBoldItalic.woff2") format("woff2"),
    url("../public/fonts/Urbanist-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist";
  src: url("../public/fonts/Urbanist-MediumItalic.woff2") format("woff2"),
    url("../public/fonts/Urbanist-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist";
  src: url("../public/fonts/Urbanist-ThinItalic.woff2") format("woff2"),
    url("../public/fonts/Urbanist-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist";
  src: url("../public/fonts/Urbanist-Regular.woff2") format("woff2"),
    url("../public/fonts/Urbanist-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist";
  src: url("../public/fonts/Urbanist-LightItalic.woff2") format("woff2"),
    url("../public/fonts/Urbanist-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist";
  src: url("../public/fonts/Urbanist-Thin.woff2") format("woff2"),
    url("../public/fonts/Urbanist-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Urbanist" !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
input,
textarea,
td,
th {
  font-family: "Urbanist" !important;
}

li {
  list-style: none;
}

a {
  text-decoration: none;

  &:hover {
    color: $c_secondary;
  }
}

.conta_iner {
  width: 90%;
  max-width: 1320px;
  margin-inline: auto;
}

.text_center {
  text-align: center;
}

.position_relative {
  position: relative;
}

/* grid */
.gap_p,
.gap_m {
  --gap_x: 30px;
  --gap_y: 30px;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--gap_y) * -1);
  margin-left: calc(var(--gap_x) * -1);
  @include breakpoint(ipadLandsacpe){
    --gap_x: 20px;
    --gap_y: 20px;
  }

  >* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    margin-top: var(--gap_y);
  }

  &.gap_y_0 {
    --gap_y: 0px;
  }

  &.gap_x_0 {
    --gap_x: 0px;
  }

  &.aic {
    align-items: center;
  }
}

.gap_p>* {
  padding-left: var(--gap_x);
}

.gap_m>* {
  margin-left: var(--gap_x);
}

.d_block {
  display: block;
}

/* grid end */
/* common width */

.w {
  &_50 {
    width: 50%;
  }

  &_100 {
    width: 100%;
  }
}

/* common width end */
/* side container */
.side_container {
  width: calc(100% - (100% - 90%) / 2);
  max-width: calc(100% - (100% - 1170px) / 2);
}

.d_flx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

button {
  cursor: pointer;
  font-family: "Urbanist" !important;
}

body,
p,
li {
  color: #081630;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  font-family: "Urbanist", sans-serif;
}

:is(p, ul, ol, li):not(:last-child, :only-child) {
  margin-bottom: 16px;
}

:is(li.MuiMenuItem-root) {
  font-size: 14px;
  padding: 5px 10px;
  font-weight: 500;

  &.Mui-selected,
  &.Mui-selected:hover {
    background-color: rgba(86, 187, 109, .1);
  }

  &:not(:last-child) {
    margin-bottom: 0;
  }
}

.fs {

  &_21,
  &_16,
  &_16 {
    :is(h1, h2, h3, h4, h5, h6) {
      color: $head_color;
      font-weight: 700;
    }
  }

  &_21 {
    :is(h1, h2, h3, h4, h5, h6) {
      font-size: 21px;
    }

    p {
      color: $c_text_grey2;
      font-size: 11px;
    }
  }

  &_16 {
    :is(h1, h2, h3, h4, h5, h6) {
      font-size: 16px;
    }
  }

  &_22 {
    :is(h1, h2, h3, h4, h5, h6) {
      font-size: 22px;
      font-weight: 500;
      color: $body_color;
      @include breakpoint(ipadLandsacpe){
        font-size: 18px;
      }
    }
  }

  &_28 {
    :is(h1, h2, h3, h4, h5, h6) {
      font-size: 28px;
    }
  }
}

.ps_rlt {
  position: relative;
}

// Common

main:not(.dashboard_main),
.main_bx {
  padding-top: 0px;
  min-height: calc(100vh - (130px + 106px));
  @include breakpoint(ipadLandsacpe){
  min-height: calc(100vh - (130px + 102px));

  }
}

h6,
.hd_6 :where(h1, h2, h3, h4, h5, h6) {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
}


// Auth

.sidebr-lst {
  .lst-itm {
    padding: 5px 20px;
    min-height: 40px;
    gap: 10px;
    transition: $transition;
    background: none !important;

    &:hover {
      background-color: $light_gray !important;
    }

    .MuiListItemIcon-root {
      height: 35px;
      min-width: 35px;
      color: $c_primary;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: $c_white;

      svg {
        height: 20px;
        width: 20px;
      }
    }

    .lstitm-txt {
      margin: 0;
      color: $c_primary;
      display: inline-flex;
      transition: $transition;

      span {
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.3px;
        line-height: 1.2;
        transition: $transition;
        color: $c_black;

      }
    }

    &.Mui-selected {
      background: none;

      .lstitm-txt {
        color: $c_white;

        span {
          font-weight: 600;
          color: $c_secondary;
        }
      }
    }
  }

  &:hover {
    >.lstitm-txt {
      span {
        color: $c_secondary;
      }
    }
  }
}

.search_bar {
  // max-width: 350px;
  position: relative;
  width: 100%;

  max-width: 250px;

  @include breakpoint(ipadLandsacpe){
    max-width: 180px;
    margin-left: auto;
  }
  @include breakpoint(mobile){
    max-width: 90%;
    width: auto;
    min-width: 180px;
    + .btn{
      min-width: 130px;
    }

  }
  @include breakpoint(mobileSmall){
    min-width: 150px;
    max-width: 100%;
  }
  .search_icon {
    position: absolute;
    // width: 50px;
    // height: 50px;
    margin: 0;
    max-height: initial;
    color: #fff;
    // right: 10px;
    background: #56bb6d;
    // border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    right: 0;
    width: 40px;
    height: 40px;
    border-radius: 5px;

    svg {
      width: 26px;
      height: 23px;
    }
  }

  input {
    // padding: 20px 65px 20px 15px;
    background-color: $c_white;
    box-shadow: 0px 2px 36px #0000001A;
    // border-radius: 8px;


    padding: 0px 70px 0px 10px;
    border-radius: 5px;
    font-size: 14px;
    height: 40px;
  }

  .cross_btn {
    position: absolute;
    right: 45px;
    cursor: pointer;

    svg {
      font-size: 20px;
    }
  }

  .MuiInputBase-root {
    padding: 0;

  }

  fieldset {
    border: none;
  }
}

// Sidebar

/* btn css */
button {
  transition: $transition;
}

.text_end {
  text-align: right;
}

.btn,
.btn.MuiButton-root {
  height: auto;
  min-height: 50px;
  min-width: 200px;
  font-size: 18px;
  font-weight: 700;
  padding: 5px 25px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  text-indent: 0px;
  position: relative;
  letter-spacing: 0px;
  text-transform: capitalize;
  box-shadow: none !important;
  transition: $transition;
  border: 1px solid transparent;
  font-family: $f_body;
  position: relative;
  overflow: hidden;
  z-index: 1;
  @include breakpoint(mobileSmall){
    font-size: 16px;
    min-width: 150px;
    min-height: 45px;
  }
  &.btn_sm {
    min-width: 1px;
    min-height: 40px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.3px;
    padding: 5px 12px;
    @include breakpoint(mobileSmall){
      font-size: 13px;
      padding: 5px 8px;
    }
  }

  &.btn_lg {
    min-height: 70px;
    min-width: 225px;
    font-size: 26px;
    border-radius: 10px;
  }

  &::before {
    content: "";
    top: 0;
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: -1;
    animation: slide 2s infinite;
    background: linear-gradient(to right,
        rgba(64, 235, 241, 0) 0%,
        rgba(255, 254, 253, 0.56) 50%,
        rgba(255, 254, 253, 0.28) 69%,
        rgba(64, 235, 241, 0) 100%);
  }

  &.btn_primary {
    color: $c_white;
    background-color: $c_secondary;
    border-color: $c_secondary;

    &:is(:hover, :focus) {
      color: $c_white;
      background-color: $c_secondary;
      border-color: $c_secondary;
    }

    &.br {
      color: $c_white;
      background-color: $c_primary;
      border-color: #9d9d9d;

      &:is(:hover, :focus) {
        color: $c_secondary;
        background-color: $c_primary;
        border-color: $c_secondary;
      }
    }
  }

  &.btn_danger {
    background-color: $danger;
    color: $c_white;
    cursor: pointer;
  }

  &.btn_secondary {
    color: $c_primary;
    background-color: $c_secondary;
    border-color: $c_secondary;

    &:is(:hover, :focus) {
      color: $c_primary;
      background-color: $c_white;
      border-color: $c_secondary;
    }
  }

  &.btn_white {
    color: $c_text;
    background-color: $c_white;
    border-color: $c_primary;

    &:is(:hover, :focus) {
      color: $c_white;
      background-color: $c_primary;
      border-color: $c_primary;
    }
  }
}

.btn {
  &_link {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
    color: $c_secondary;
    display: inline-flex;
    align-items: center;
    transition: $transition;
    gap: 8px;

    &.sm {
      font-size: 12px;
      font-weight: 600;
    }

    svg {
      font-size: 13px;
    }

    &:hover {
      text-decoration: underline !important;
      text-underline-offset: 3px;
    }
  }
}

/* button css end */
@keyframes slide {
  0% {
    left: -100%;
  }

  100% {
    left: 100%;
  }
}

.sidebar-main-div {
  .sidebar_drawer {
    .MuiDrawer-paper {



      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 66px #0000001a;
      border-radius: 20px;
      border: none;

      z-index: 0;
      @include breakpoint(ipadLandsacpe){
        max-width: 100%;
      }
    }
  }
}

.srch_bar {
  max-width: 100%;
  flex-wrap: wrap;
  margin-top: 50px;
}

.srch_bar h1 {
  font-size: 22px;
  font-weight: 500;
  color: #393939;
}

.rpt_card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 13px 25px #00000012;
  border-radius: 20px;
  padding: 30px;
  @include breakpoint(mobileSmall){
    padding: 20px 15px;
  }
}

/* paginatin css */
.pagination_wrp {
  @include d-flex();
  margin-top: 30px;
  @include breakpoint(mobile){
    margin-top: 20px;
    row-gap: 8px;
  }

  >p {
    margin: 0;
    color: $c_black;
    font-size: 14px;
    line-height: 1.07;
  }

  .pagination_list {
    @include d-flex();
    border: 1px solid $border_color;
    margin: 0;
    border-radius: 10px;

    li {
      margin: 0;
      min-width: 41px;
      min-height: 41px;
      border-radius: 10px;
      cursor: pointer;
      transition: $transition;
      @include d-flex(center, center);
      @include breakpoint(mobile){
        min-width: 30px;
        min-height: 30px;
        border-radius: 6px;
      }
      &.active {
        background: $c_secondary;
        color: $c_white;
      }

      &:hover:not(.active) {
        background: $light_gray;
      }
    }
  }

  .pagination_number {
    @include d-flex(center, flex-start, 9px);

    .page_icon {
      border: 1px solid $border_color;
      background: $c_white;
      min-width: 40px;
      min-height: 40px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      @include breakpoint(mobile){
        min-width: 30px;
        min-height: 30px;
        border-radius: 6px;
      }
      &:hover {
        background: $c_secondary;
        border: 1px solid $c_secondary;

        svg {
          path {

            &:first-child,
            &:last-child {
              fill: $c_white;
            }
          }
        }
      }

      .mat_icon {
        width: 25px;
        height: 25px;
        font-size: 25px;
        line-height: normal;

        path {
          &:first-child {
            fill: #81cd9326;
          }

          &:last-child {
            fill: #56bb6d;
          }
        }
      }
    }
    .MuiFormControl-root.MuiFormControl-fullWidth.all_page  {
      .MuiSelect-select{
      min-height: 40px;
      padding: 5px;
      box-sizing: border-box;
      border-radius: 10px;
      border: 1px solid #C2C2C2;
    }
      fieldset.MuiOutlinedInput-notchedOutline {
        display: none;
    }
    }
  >   .MuiFormControl-root {
      width: 50px;
      display: inline-flex;
      height: 40px;
    }
  }
}

.emp_dtl {
  --gap_x: 70px;
  --gap_y: 70px;

  .emp_dtl_lft {
    width: 30%;

    .sec_head {
      margin-bottom: 20px;

      p {
        margin: 11px 0 0;
      }
    }
  }
}

.dashboard_main.mn-lyout {
  margin: 40px 0 !important;
  width: 100%;
  @include breakpoint(mobileSmall){
  margin: 30px 0 !important;

  }

  .sub-layout {
    margin-block: 50px;
    display: flex;
    align-items: stretch;
    width: 100%;
    gap: 60px;
    padding: 0;
    margin: 0;
    max-width: 100%;
@include breakpoint(desktop){
      gap: 30px;
    }
    >.MuiBox-root:empty {
      display: none;
    }

    nav.sidebar-main-div { 
      max-width: 305px;
      min-width: 305px;
      width: 100%;
      margin: 0;
      padding: 0;
      @include breakpoint(ipadLandsacpe){
        display: none;
      }

      .sidebar_drawer .MuiDrawer-paper {
        position: relative;

        left: unset;
        top: unset;
      }
    }
  }

  .main_loyout {
    flex-grow: 1;
    width: calc(100% - 305px);
  }
}

.empl_list {
  max-height: 500px;
  overflow: auto;
  padding-right: 23px;

  li {
    @include box-shadow(16px 20px, 14px);

    .nm_img {
      @include d-flex(center);

      >span {
        width: 27px;
        height: 27px;
        border-radius: 27px;
        text-transform: uppercase;
        font-size: 12px;
        background: #35c691;
        color: $c_white;
        @include d-flex(center, center);

        &.green {
          background: #35c691;
        }

        &.blue {
          background: #266fc7;
        }

        &.red {
          background: #dc3472;
        }
      }

      >figure {
        width: 41px;
        height: 41px;
        border-radius: 14px;

        img {
          @include img_contain(cover);
        }
      }
    }

    h3 {
      font-size: 10px;
      font-weight: 700;
      color: $head_color;
      margin: 16px 0 10px;
    }

    p {
      font-size: 14px;
      color: $c_text_grey2;
      line-height: 1.43;
      display: -webkit-box;

      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

.emp_dtl_rht {
  width: 70%;
  @include box-shadow();
  padding: 18px 30px 30px;

  .sec_head {
    @include d-flex(center, space-between);
    border-bottom: 1px solid $light_gray;
    padding-bottom: 14px;
    margin-bottom: 26px;

    .btn {
      min-width: 84px;
      min-height: 26px;
      font-size: 10px;
      font-weight: 700;
      padding: 5px;
    }

    .btn_grp {
      @include d-flex(center, flex-start, 20px);

    }
  }
}

.gap_m {
  .form_control {
    width: calc(100% - (var(--gap_x)));
    margin-bottom: 0;
  }
 
}

.form_control {
  &.w_50 {
    width: calc(50% - (var(--gap_x)));
    @include breakpoint(mobileSmall){

    width: calc(100% - (var(--gap_x)));
    }
  }

  &.form_control_bg {
    :is(input, select, .MuiSelect-select, textarea) {
      background-color: $light_gray;
      box-shadow: inset rgba(0, 0, 0, 0.16) 0px 0px 0.50px 0px;
      border-radius: 14px;
      box-sizing: border-box;
    }

    .MuiInputBase-root.MuiOutlinedInput-root {
      padding-right: 0;
    }
    .MuiAutocomplete-input{
      padding: 5px 15px !important;
    }
    label {
      display: block;
      letter-spacing: 0.63px;
      color: $c_text_grey3;
      font-size: 12px;
      margin-bottom: 10px;
      font-weight: 700;
      position: relative;
      @include breakpoint(mobile){
        letter-spacing: 0;
      }
      @include breakpoint(mobileSmall){
        margin-bottom: 4px;
      }
    }
  }
  fieldset.MuiOutlinedInput-notchedOutline{
    display: none;
  }
  .MuiInputBase-root.MuiOutlinedInput-root{
    padding: 0 !important;
  }
}

.text_field-text > div{
  padding: 0;
}
.form_title {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    @include d-flex(center, flex-start, 10px);
    font-size: 18px;
    font-weight: 800;
    border-left: 4px solid $c_secondary;
    padding-left: 15px;
    @include breakpoint(mobileSmall){
      font-size: 16px;
      padding-left: 10px;
      border-width: 2px;
    }
  }

  &.mt_40 {
    margin-top: 40px;
  }

  .pos_delete {
    padding: 0;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ff0000;
    color: #ff0000;
    transition: $transition;
    margin-left: 5px;

    svg {
      font-size: 14px;
    }

    &:hover {
      color: $c_white;
      background-color: #ff0000;
    }
  }
}

.nft_dtl {

  h2 {
    font-size: 15px;
    margin-bottom: 18px;
  }

  h3 {
    font-size: 14px;
    letter-spacing: 0.75px;
    margin-bottom: 14px;
  }

  &.mt_30 {
    margin-top: 30px;
  }

  &.mt_10 {
    margin-top: 10px;
  }

}

.right_s {
  .swiper-slide {
    padding-bottom: 20px;
    height: auto;
  }

  .expore_box_single {
    @include box_shadow(0 0 18px, 14px 14px 0 0);
    height: 100%;

    overflow: hidden;

    figure {
      width: 100%;

      height: 165px;

      img {
        @include img_contain(cover);
      }
    }

    h4 {
      font-size: 11px;
      font-weight: 700;
      color: $c_text;
      @include d-flex(center, space-between);
      margin: 10px 0 6px;
    }

    p {
      font-size: 9px;
      color: $c_text;
    }

    h4,
    p {
      padding-inline: 12px;
    }

  }
}

.form_group {
  >.form_control {
    margin-bottom: 0;
  }

  .form_left {
    width: calc(75% - var(--gap_x));
    @include breakpoint(mobile){
    width: calc(100% - var(--gap_x));

    }
  }

  .prfl_pic.form_control {
    width: calc(25% - var(--gap_x));
    @include breakpoint(mobile){
      width: calc(50% - var(--gap_x));
      order: -1;
    }
    &.w_50{
    width: calc(50% - var(--gap_x));
    @include breakpoint(mobileSmall){
    width: calc(100% - var(--gap_x));

    }

    }

    &.w_100 {
      width: calc(100% - var(--gap_x));
 .upload_image{
        width: 100%;
      }
    }

    &.w_30 {
      width: calc(30% - var(--gap_x));

    }

    figure {
      width: 120px;
      height: 150px;
      border-radius: 14px;
      overflow: hidden;
      border: 0;
      padding: 0;
      @include breakpoint(mobile){
        width: 100px;
        height: 110px;
      }

      img {
        @include img_contain(cover);
        border-radius: inherit;
      }
    }
  }
}

body {
  .MuiSwitch-root {
    padding: 0;
    width: 37px;
    height: 22px;

    .MuiSwitch-switchBase {
      padding: 0;
      color: #F3F3F3;
      height: 22px;
      left: 2px;

      .MuiSwitch-thumb {
        width: 18px;
        height: 18px;
      }

      +.MuiSwitch-track {
        opacity: 1;
        border-radius: 15px;
        background-color: #C9C9C9;
      }

      input {
        left: -70%;
      }

      &.Mui-checked {
        color: #E8F5E9;
        transform: translateX(15px);

        input {
          left: -160%;
        }

        +.MuiSwitch-track {
          background-color: $c_secondary;
        }
      }
    }
  }
}

.form {
  &_control {
    margin-bottom: 20px;

    .gap_p {
      --gap_x: 24px;
      --gap_y: 24px;
    }

    h5 {
      color: $c_white;
      font-size: 16px;
      font-weight: 700;
      padding-bottom: 30px;
    }

    label {
      color: #393939;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.2;
      display: block;

      &:not(:last-child) {
        margin-bottom: 10px;
        @include breakpoint(mobileSmall){
          margin-bottom: 4px;
        }
      }

      h6 {
        color: $c_white;
        font-size: 16px;
        font-weight: 700;
        padding-bottom: 5px;
      }
    }

    :is(input, select, .MuiSelect-select, textarea) {
      width: 100%;
      color: $c_text;
      font-size: 16px;
      font-weight: 400;
      border-radius: 5px;
      border: 1px solid $c_white;
      background: $c_white;
      outline: none;
      box-shadow: none;
      box-sizing: border-box;
      font-family: $f_body;
      transition: $transition;
      @include breakpoint(mobileSmall){
        font-size: 14px;
      }

      &:focus {
        border-color: $c_secondary;
      }

      &::placeholder {
        color: #909090;
        opacity: 1;
      }

      &:disabled {
        color: $c_text;
        cursor: not-allowed;
        -webkit-text-fill-color: $c_text;
        opacity: 0.5;
      }
    }

    .search_field {
      input {
        background-image: url("../public/images/icon_search.svg");
        background-position: right 20px center;
        background-size: 25px;
        background-repeat: no-repeat;
        padding-right: 65px !important;
      }
    }

    input {
      height: 50px;
      padding: 5px 15px;
      @include breakpoint(mobileSmall){
        height: 45px;
      
      }
    }

    select {
      height: 50px;
      padding: 0 60px 0 15px;
    }

    .MuiSelect-select {
      display: inline-block;
      height: 50px !important;
      line-height: 50px !important;
      padding: 0 60px 0 15px !important;
      font-family: $f_body;
      line-height: 1;

      &[aria-expanded="true"] {
        border-color: $c_secondary;
      }

      ~fieldset {
        display: none;
      }

      ~.MuiSvgIcon-root {
        right: 10px;
        font-size: 35px;
        color: $c_primary;
      }
      @include breakpoint(mobileSmall){
        height: 45px !important;
        line-height: 45px !important;
        padding: 0 37px 0 15px !important;
      }
    }

    textarea {
      height: 100px;
      padding: 10px 15px;
    }

    .MuiFormGroup-root {
      &.flex {
        flex-direction: row;
        gap: 20px 50px;

        label {
          margin-bottom: 0;
        }
      }
    }

    .label_checkbox {
      display: inline-flex;
      align-items: center;
      gap: 10px;
      width: auto;
      cursor: pointer;
      margin: 0;

      &:not(:last-child) {
        margin: 0 0 20px;

        &.mb_0 {
          margin: var(--gap_y) 0 0;
        }
      }

      .MuiRadio-root {
        padding: 0;

        .MuiSvgIcon-root {
          fill: $c_white;

          &:nth-child(2) {
            transform: scale(1.7);
          }
        }

        &.Mui-checked {
          color: $c_secondary;

          .MuiSvgIcon-root {
            fill: currentColor;

            &:nth-child(2) {
              transform: scale(1);
            }
          }
        }
      }

      input {
        accent-color: $c_secondary;

        &[type="checkbox"] {
          width: 16px;
          height: 16px;
        }

        &[type="radio"] {
          width: 20px;
          height: 20px;
        }
      }

      >span {
        font-size: 14px;
        font-weight: 500;
        color: $c_white;
        font-family: $f_body;
      }
    }

    div.label_checkbox {
      label {
        color: $c_white;
        margin: 0;
        display: inline-flex;
        align-items: center;
        gap: 5px;

        >span {
          font-size: 14px;
          font-weight: 500;
          color: $c_white;
          font-family: $f_body;
        }
      }

      .MuiCheckbox-root {
        padding: 0;
        position: relative;
        top: 1px;

        &.Mui-checked {
          color: $c_secondary;
        }
      }

    }

    .upload_image {
      display: inline-flex;
      cursor: pointer;
      margin-bottom: 0 !important;

      input[type="file"] {
        position: absolute;
        inset: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        cursor: pointer;
        text-indent: -1000px;
      }

      &_holder {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
    

        figure {
          height: 60px;
          width: 60px;
          border: 1px solid $c_secondary;
          background-color: $c_white;
          border-radius: 50%;
          padding: 2px;
          transition: $transition;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        svg {
          width: 21px;
          height: 21px;
          border-radius: 50%;
          border: 1px solid $c_white;
          background: $c_secondary;
          color: $c_white;
          padding: 2px;
          margin-top: -10.5px;
          transition: $transition;
        }
      }

      &:hover {
        .upload_image_holder {
          figure {
            border-color: $c_primary;
          }

          svg {
            background-color: $c_primary;
          }
        }
      }

      &.full_img {
        width: 100%;

        .upload_image_holder {
         
          figure {
            width: 100%;
            height: 250px;
            border-radius: 5px;
    

            img {
              border-radius: 5px;
              object-fit: cover;
            }
          }
       
        }
        .upload_image_holder {

          width: 100%;
      }
      }

      &.upload_docs {
        width: 100%;
        &.w_50{
          width: calc(50% - var(--gap_x));
          @include breakpoint(mobileSmall){
            width: calc(100% - var(--gap_x));
          }
        }
        .upload_image_holder {
          width: 100%;
        
          figure {
            width: 100%;
            height: 200px;
            border-radius: 5px;
                   
           

            img {
              border-radius: 5px;
              object-fit: contain;
            }
          }
        }
      }
    }

    .MuiInputBase-hiddenLabel {
      .MuiOutlinedInput-notchedOutline {
        display: none;
      }
    }

    .MuiNativeSelect-root {

      &::after,
      &::before {
        display: none;
      }

      select {
        padding-right: 50px !important;

        option {
          color: $c_black;
        }

        &:focus {
          border-radius: 8px;
          background: $c_white;
        }
      }

      .MuiNativeSelect-icon {
        right: 10px;
        font-size: 35px;
        color: $c_secondary;
      }
    }

    .css-8t3bai-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
      color: $c_secondary;
    }

    .css-8t3bai-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
      background-color: #DEF1E3;
    }
  }
  input:has(+ .eye_btn) {
    padding-right: 50px;
    @include breakpoint(mobile) {
      padding-right: 45px;
    }
  }
  .eye_btn {
    position: absolute;
    height: 50px;
    max-height: 50px;
    width: 50px;
    margin: 0;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @include breakpoint(mobile) {
      height: 45px;
      max-height: 45px;
      width: 45px;
    }
    button {
      height: 80%;
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0 auto;
      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
  &_btn {
    margin-top: 30px;
    @include breakpoint(mobileSmall){
      margin-top: 20px
    }
  }
}

.mng_role_wrp,
.cms_pages,
.manage_blog,
.dashboard_wrp,
.profile_page {
  @include box_shadow2(30px, 20px);
  width: calc(100% - 365px);
  @include breakpoint(ipadLandsacpe){
    width: 100%;
  }
  @include breakpoint(mobile){
   padding: 20px 15px;
  }
  &.p_0 {
    padding: 0;

    .pagination_wrp {
      // padding: 40px 25px;

    }
  }

  .pagination_wrp {
    // padding: 40px 0;

  }
}

.table_container {
  .table {
    thead {
      background: #56BB6D;
      border-radius: 10px 10px;
      overflow: hidden;

      tr {
        th {
          font-size: 16px;
          font-weight: 700;
          color: $c_white;
          white-space: nowrap;
          @include breakpoint(mobile){

            font-size: 14px;
    
            }
        }
      }
    }

    tr {

      th,
      td {
        font-size: 14px;
        color: $c_black;
        border-bottom: 1px solid $light_gray;
        letter-spacing: 0;
        line-height: 1.2;
        font-style: normal;

      }

      th {
        padding: 10px 15px;
        @include breakpoint(mobile){
          padding: 8px;

  
          }
      }

      td {
        padding: 10px 15px;
        min-width: 100px;
        @include breakpoint(mobile){
        padding: 8px;
        font-size: 13px;

        }

        p {
          font-size: 14px;
          color: $c_black;
          letter-spacing: 0;
          line-height: 1.2;
          @include breakpoint(mobile){
         
            font-size: 13px;
    
            }
        }
      }

      &:hover {
        box-shadow: 0px 1px 28px #0000001F;
      }
    }

    .form_control.form_control_bg {
      margin-bottom: 0;
      width: 180px;

      :is(input, select, .MuiSelect-select, textarea) {
        background: #56BB6D38;
        font-size: 14px;
      }
    }

    .btn {
      min-width: 90px;
      min-height: 36px;
      font-size: 16px;
    }

    .reply_btn {
      min-width: 30px;
      min-height: 30px;
      padding: 5px;

      svg {
        font-size: 16px
      }
    }

    .nft_img {
      width: 50px;
      height: 50px;
      @include breakpoint(mobile){
        width: 35px;
        height: 35px;
      }

      img {
        @include img_contain(cover);
      }
    }
  }
}

.sec_head {
  &.fs_22 :is(h1, h2, h3, h4, h5, h6) {
    font-weight: 700;
  }
}

.coupon {
  @include box_shadow(25px 29px, 20px);
  width: 100%;
  @include breakpoint(mobile){
  @include box_shadow(25px 15px, 20px);

  }

  .sec_head {
    margin-bottom: 30px;
    @include d-flex(center, space-between);
    @include breakpoint(mobile){
      gap: 10px 16px;
    }  
    .btn_group {
      @include d-flex(center, flex-end, 15px);
      flex: 1;
      flex-wrap: nowrap;

      .btn {
        min-width: 40px;
        min-height: 40px;
        padding: 2px;
        border-radius: 5px;

        &.btn_sm {
          padding: 5px 12px;
        }
      }
    }

    h2 {
      color: $body_color;
      font-size: 22px;
      font-weight: 700;
      @include breakpoint(mobile){
        font-size: 18px;
      }
      @include breakpoint(mobileSmall){
        font-size: 16px;
      }
    }
  }
}

.coupon_card {
  cursor: pointer;
  width: calc(33.33% - calc(var(--gap_x)));
 @include breakpoint(ipad){
  width: calc(100% / 2  - calc(var(--gap_x)));
 }
 @include breakpoint(mobileSmall){
  width: calc(100%  - calc(var(--gap_x)));
 }
  .coupon_card_dtl {
    @include d-flex(stretch, space-between);
    background: #DEF1E3;
    min-height: 120px;
    padding: 10px 0;
    border-radius: 14px;

    >figure,
    .coupon_btn {
      width: 23%;
      position: relative;

      &::after,
      &::before {
        content: "";
        position: absolute;
        inset: -17px -7px auto auto;
        width: 12px;
        height: 12px;
        border-radius: 20px;
        background: $c_white;
      }

      &::before {
        bottom: -17px;
        top: unset;
      }
    }

    .coupon_dtl {
      width: 54%;
      padding-inline: 10px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      border-right: 1px dashed rgb(13 66 134 / 25%);
      border-left: 1px dashed rgb(13 66 134 / 25%);

      svg {
        &[data-testid="AddIcon"] {
          font-size: 40px;
        }
      }

      .uploaded_coupon {
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }
    }

    >figure {
      display: inline-flex;
      justify-content: center;
      text-align: center;
      padding-inline: 10px;
      position: relative;
      span {
        writing-mode: vertical-lr;
        font-size: 14px;
        font-weight: 600;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(-1);
        white-space: nowrap;
        background: #fff;
        line-height: 1;
        padding: 5px 2px;
      }

      img {
        @include img_contain(cover);
        max-width: 35px;
        margin-inline: auto;
      }
    }

    .coupon_btn {
      display: flex;
      justify-content: center;
      padding-inline: 10px;

      &::after,
      &::before {
        right: unset;
        left: -6px;
      }

      .btn {
        min-width: 40px;
        min-height: unset;
        writing-mode: vertical-rl;
        padding: 5px;
        border-radius: 14px;
        transform: scale(-1);
      }
    }

    h2 {
      color: $body_color;
      line-height: 1.4;
    }

    p {
      font-size: 12px;
      font-weight: 500;
      color: $body_color;
      margin-top: 7px;
    }

  }

  &.redeemed_card {
    .coupon_card_dtl {
      background: #EAEAEA;
    }
  }
}

.coupon_flx {

  .coupon_avl {
    @include d-flex(center, center, 10px);
    margin-top: 10px;

    label {
      margin: 0;
      letter-spacing: 0;
    }

    p {
      color: $c_primary;
      font-size: 12px;
      font-weight: 500;
      margin: 0;

      &.mr_auto {
        margin-right: auto;
      }

      &.cursor_pointer {
        cursor: pointer;
        transition: $transition;

        &:hover {
          color: $c_secondary;
          text-decoration: underline;
          text-underline-offset: 2px;
        }
      }
    }

    .link {
      font-size: 12px;
      color: $c_secondary;
      font-weight: 500;
      background: transparent;
      border: 0;
      outline: 0;
    }
  }
}

.coupon_dtl_page {
  .sec_head {
    margin-bottom: 22px;
  }

  .coupon_card {
    width: calc(50% - (var(--gap_x)));
@include breakpoint(mobileSmall){
  width: 100% ;

}
    &.w_100 {
      width: calc(100% - (var(--gap_x)));
      @include breakpoint(mobile){
        max-width: 450px;
      }
    }

    .coupon_card_dtl {
      min-height: 160px;

      p.code {
        color: #56BB6D;
        margin: 7px 0 12px;
      }

      .coupon_dtl {
        .uploaded_coupon {
          height: 140px;
        }
      }
    }
  }

  .coupon_status.sec_head {
    width: calc(50% - (var(--gap_x)));
    margin-bottom: 0;
    display: block;

  }


  .cpn_status_check {
    max-width: 250px;
    margin-top: 15px;

    label.radio_check {
      flex-direction: row-reverse;
      display: flex;
      margin: 0;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      .MuiButtonBase-root {
        padding: 0;
      }

      svg.MuiSvgIcon-root path {
        fill: $c_secondary;
      }

      svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-11zohuh-MuiSvgIcon-root path {
        fill: $c_secondary;
      }

      span.MuiTypography-root {
        font-size: 16px;
        font-weight: 500;
        color: $body_color;
      }
    }

    .css-1odxfd9-MuiButtonBase-root-MuiRadio-root:hover {
      background-color: transparent !important;
    }

    .css-8je8zh-MuiTouchRipple-root {
      display: none;
    }
  }

  .coupon_frm_wrp {
    margin: 30px 0 40px;
    @include breakpoint(mobile){
      &:has(.submit_btn) ,&:has( + .submit_btn){
      margin-bottom: 0;
    }
  }

    .gap_m {
      --gap_y: 20px;
    }

    .form_group {
      .form_control {
        margin-bottom: 0;

        .MuiSwitch-root {
          height: 18px;
          width: 30px;
          position: absolute;
          top: -3px;
          margin-left: 10px;

          .MuiSwitch-switchBase {
            height: 18px;
            left: 2px;

            .MuiSwitch-thumb {
              width: 14px;
              height: 14px;
            }

            &.Mui-checked {
              left: -1px;
            }
          }
        }
      }
    }
  }

  .assign_copn_wrp {
    .sec_head {
      h2 {
        font-weight: 700;
        font-size: 20px;
        color: #121212;
      }

      .btn {
        padding: 5px;
        min-width: 30px;
        min-height: 30px;
      }
    }
  }

  .assign_card {
    @include box_shadow(24px);
    @include d-flex(stretch, flex-start, 22px);
    transition: $transition;

    &:hover {
      @include box_shadow2(24px);
      transform: translateY(-4px);
    }

    width: calc(33.33% - var(--gap_x));

    >figure {
      width: 73px;
      height: 73px;
      min-width: 73px;
      border-radius: 73px;

      img {
        @include img_contain(cover);
      }

    }

    h3 {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -0.17px;
      color: $head_color;
    }

    p {
      font-size: 12px;
      letter-spacing: -0.21px;
      color: $c_text_grey2;
      margin: 6px 0 8px;
    }

    span.tick {
      width: 20px;
      height: 20px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      background: $c_secondary;

      svg.MuiSvgIcon-root {
        font-size: 14px;
        color: $c_white;
      }
    }

    &.active {
      >figure {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          inset: auto 0px 3px auto;
          background: $c_secondary;
          border: 4px solid $c_white;
          width: 15px;
          height: 15px;
          border-radius: 15px;
          box-sizing: border-box;
        }
      }
    }
  }
}

.admin_cooupn {
  --gap_y: 24px;

  .form_group {
    .form_control {
      margin-bottom: 0;
    }
  }

  .coupon_flx {
    --gap_x: 108px;

  }

  .assign_copn_wrp {
    margin-top: 35px;
  }
}
@include breakpoint(mobile){
  .coupon_dtl_page{
    .coupon_flx{
      > div{
        width: 100%;
      }
    }
  }
}
.org_dtl_page {
  .coupon_flx {
    --gap_x: 24px;
    --gap_y: 24px;

  }
}

ul.MuiList-root.sidebr-lst {
  min-height: 500px;

  li.MuiListItem-root {
    padding: 0;
    margin: 0;
  }

  .main_prfl.MuiListItem-root {

    margin: 7px 0 25px;

    h2 {
      line-height: normal;
    }

    >figure {
      width: 80px;
      height: 80px;
      border-radius: 80px;

      img {
        @include img_contain(cover);

      }
    }
  }
}

.dialog {
  @include box_shadow(24px);

  &.revert_query {
    h2 {
      margin-bottom: 10px;
    }
  }

  .btn {
    min-height: 45px;
    min-width: 150px;
    font-size: 18px;
  }
}

.MuiDialog-paper.MuiDialog-paperScrollPaper:has(.revert_query) {
  max-width: 450px;
  width: 100%;
}

/* bredcrum css */
.site_pagination {
  display: flex;

  li {
    margin: 0;
    font-size: 22px;
    font-weight: 500;
    color: $c_secondary;

    &:not(:last-child) {
      &::after {
        content: ">";
        color: #393939;
        display: inline-flex;
        margin: 0 8px;
      }
    }

    a {
      color: #393939;
      display: inline-flex;

      &:hover {
        color: $c_secondary;
        text-decoration: underline !important;
      }
    }
  }
}

/* tab css */
.custom_tabs {
  .custom_tabs_links {
    margin-bottom: 15px;

    .MuiTabs-flexContainer {
      display: inline-flex;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      padding: 5px;
      gap: 5px;
      @include breakpoint(mobile){
        overflow: auto;
      }

      +.MuiTabs-indicator {
        display: none;
      }

      button {
        min-height: 40px;
        padding: 0 15px;
        font-size: 14px;
        font-weight: 700;
        color: $c_text_grey;
        text-transform: capitalize;
        border-radius: 5px;
        line-height: normal;
        transition: $transition;
        @include breakpoint(mobileSmall){
          font-size: 12px;
        }

        &:hover {
          color: $c_black;
          background-color: rgb(32 78 51 / 10%);
        }

        &.Mui-selected {
          color: $c_white;
          background-color: $c_secondary;
        }
      }
    }
  }

  [role="tabpanel"] {
    >.MuiBox-root {
      padding: 0;
    }
  }
}

.short_disc {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 300px;
  width: 100%;
  min-width: 200px;
}

/* tab css end */

.cms_pages,
.mng_role_wrp {
  .cards {
    box-shadow: unset;
  }

  .sec_head {
    margin-bottom: 30px;
    @include d-flex(center, space-between);
    @include breakpoint(mobile){
      margin-bottom: 16px;
      gap: 10px;
    }
    .btn {
      min-width: 1px;
      min-height: 40px;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.3px;
      padding: 5px 12px;
      white-space: nowrap; 
    }

    h2,
    h1 {
      color: #393939;
      font-size: 22px;
      font-weight: 700;
      @include breakpoint(mobile){
        font-size: 18px;
      }
      @include breakpoint(mobileSmall){
        font-size: 16px;
      }
    }
  }
}

.faq_box {
  max-width: 70%;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  @include breakpoint(mobileSmall){
    width: 100%;
    max-width: 100%;
  }
  .btn {
    position: absolute;
    left: calc(100% + 10px);
    top: 0;
    min-width: 100px;
    min-height: 40px;
    font-size: 16px;
    @include breakpoint(mobileSmall){
      left: auto;
    top: 16px;
    background: transparent !important;
    min-width: auto;
    min-height: auto;
    font-size: 14px;
    border: 0 !important;
    padding: 0;
    color: $c_secondary !important;
    right: 20px;
    &.MuiButton-root.btn_danger{
      color: #D8502C !important;
    }
    }
  }

  p {
    display: block;
    letter-spacing: 0.63px;
    color: $c_text;
    font-size: 13px;
    margin-bottom: 10px;
    font-weight: 700;
    position: relative;
  }
}

/* graph */
.dashGraph_item {
  .sec_head {
    @include d-flex(center, space-between);
    margin-bottom: 24px;
  }

  .form_control {
    margin-bottom: 0;
  }

  .graph {
    width: 100%;
  }

}

.dashGraph {
  .cards {
    @include box_shadow2(20px, 14px);
    @include breakpoint(mobileSmall){
      @include box_shadow2(15px 10px, 14px);
    }

    .sec_head {
      h2 {
        font-size: 18px;
        font-weight: 600;
        @include breakpoint(mobileSmall){
          font-size: 16px;
        }
      }
    }

    :is(input, select, .MuiSelect-select, textarea) {
      min-width: 150px;
      @include breakpoint(mobileSmall){
        min-width: 110px;
      }
    }
  }
}

.manage_blog {
  .sec_head {
    margin-bottom: 30px;
    @include d-flex(center, space-between);
    @include breakpoint(mobile){
    column-gap:14px;
    }
    @include breakpoint(mobileSmall){
      &:has(.search_bar){
     
    @include d-flex(center, space-between,10px);
  }
    }
  }

  .form_group {
    .prfl_pic.form_control {
      figure {
        width: 400px;
        height: 250px;
        @include breakpoint(ipad){
          width: 100%;
       
        }
        @include breakpoint(mobileSmall){
          height: 190px;
        }
      }
      @include breakpoint(ipad){
      .upload_image_holder{
        width: 100%;
      }
    }

    }
  }

  &.manage_banner {
    .form_group {

      .prfl_pic.form_control {
        figure {
          width: 100%;
          height: 200px
        }

        .upload_image_holder {
          width: calc(100% - var(--gap_x));
        }
      }

      .w_70 {
        width: calc(70% - var(--gap_x));

      }
    }
  }

  .repeat_banner {
    padding: 30px;
    background: $light_gray;
    border-radius: 14px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    label {
      display: block;
      letter-spacing: 0.63px;
      color: #C7C7C7;
      font-size: 10px;
      margin-bottom: 10px;
      font-weight: 700;
      position: relative;
    }

    .link {
      background: transparent;
      border: 0;
      color: $c_secondary;
      font-size: 18px;
      font-weight: 500;
      transition: $transition;

      &:hover {
        text-decoration: underline
      }

      &.danger {
        color: $danger;
      }
    }
  }

  &.view_nft {
    .form_group {
      .prfl_pic.form_control {
        figure {
          width: 100%;
          height: 200px
        }

      }

      .upload_image {
        margin-bottom: 24px !important;
      }
    }

    .form_control.w_70 {
      width: calc(70% - var(--gap_x));

      .nft_purchased {
        h2 {
          margin-bottom: 16px;
          font-size: 22px;
          color: $body_color;
          font-weight: 500;
        }

        .d_flex {
          @include d-flex(center, flex-start, 16px);

          .user_dtl {
            @include box_shadow(10px, 10px);
            width: calc(33.33% - 11px);

          }
        }

        &.mt_30 {
          margin-top: 30px;
        }
      }
    }
  }
}

.add_btn {
  @include d-flex(center, flex-start, 20px);

  .btn {
    min-width: 40px;
    min-height: 40px;
    padding: 2px;
    border-radius: 8px;

    &.btn_sm {
      padding: 5px 12px;
    }
  }
}

// .back_btn {
//   .btn {
//     min-width: 120px;
//     min-height: 44px;
//     font-size: 16px;
//     @include breakpoint(mobileSmall){
//       min-width: 80px;
//       min-height: 40px;
//       font-size: 14px;sdfsd
//     }
//   }
// }

.submit_btn {
  margin-top: 30px;
}

.dashboard_wrp {
  .dash_card_wrp {
    --gap_x: 18px;
    --gap_y: 18px;

    .dash_card {
      cursor: pointer;
      width: calc(25% - var(--gap_x));
      @include box_shadow(15px, 14px);
      @include d-flex(stretch, space-between);
      border: 1px solid $border_color;
      transition: $transition;
      @include breakpoint(desktop){
        width: calc(33.3333% - var(--gap_x));
      }
      @include breakpoint(ipadLandsacpe){
        width: calc(50% - var(--gap_x));
      }
      @include breakpoint(mobileSmall){
        width: calc(100% - var(--gap_x));
      }
      p {
        margin-bottom: 0px;
        font-size: 14px;
        color: $body_color;
      }

      svg.MuiSvgIcon-root {
        background: #56BB6D38;
        width: 35px;
        height: 35px;
        border-radius: 35px;
        @include d-flex(center, center);
        font-size: 16px;
        padding: 6px;

      }

      &:hover {
        @include box_shadow2(15px, 14px);
        border: 1px solid $c_secondary;
        transform: translateY(-4px);
      }
    }

  }

  .sec_head {
    margin-bottom: 16px;
  }

  .graph_card {
    margin-block: 24px;
  }
}

.profile_page {
  h1 {
    margin-bottom: 16px;
    font-weight: 700;
  }

  .form_control {
    margin-bottom: 0;
  }
}

.react-tel-input .country-list .country {

  padding: 6px 9px 7px 46px !important;
  margin-bottom: 3px;
}

.password_page {
  .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
    position: absolute;
    right: 20px;
    top: 24px;
    @include breakpoint(mobileSmall){
      top: 21px;
    }
  }

  .form_control.form_control_bg {
    :is(input, select, .MuiSelect-select, textarea) {
      padding-right: 40px;
    }

  }
}

.edit_info.org_form {
  .form_control {
    margin-bottom: 0;
    &.profile_flex{
      margin-bottom: 30px;
    }
  }

  .form_left {
    .gap_m {
      --gap_x: 20px;
      --gap_y: 20px;
    }
  }
}

.dashboard {
  .btn_group {
    @include d-flex(center, flex-end, 15px);
    flex-wrap: nowrap;
    flex: 1;
    @include breakpoint(mobileSmall){
      @include d-flex(center, flex-end, 10px);
      flex-wrap: nowrap;
    }

    .btn {
      white-space: nowrap;
    }
    @include breakpoint(mobile){
      width: 100%;
      
    }  
  }

  &.sec_head {
    margin-bottom: 30px;
    @include breakpoint(mobile){
      margin-bottom: 16px;
      gap: 10px;
    }
 
   
  }
}

.btn_group {
  .form_control {
    margin-bottom: 0;

    :is(input, select, .MuiSelect-select, textarea) {
      font-size: 14px;
      border-radius: 5px;
      font-weight: 500;
    }

    .MuiSelect-select {
      height: 40px !important;
      line-height: 40px !important;
      padding: 0 30px 0 10px !important;
      max-width: 120px;
      min-width: 120px;

      ~.MuiSvgIcon-root {
        right: 4px;
        font-size: 20px;
        color: #081630;
      }
    }

    input {
      height: 40px;
      padding: 5px 10px;
    }

    &.d_flex {
      display: inline-flex;
      align-items: center;
      gap: 5px;

      label {
        margin: 0;
        white-space: nowrap;
      }
    }
  }
}

.text_field p,
.err_msg {
  color: red !important;
  font-size: 12px !important;
  margin: 0 !important;
  font-weight: 400 !important;
  padding-top: 5px !important;
}

.loader_loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50%;
  left: 0%;

  figure {
    width: 50px;
    height: 50px;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}

.flexDiv {
  display: flex;
  width: 40%;
  margin: auto;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;

  .yesBtn {
    color: #fff;
    background-color: #56bb6d;

    &:hover {
      color: #fff !important;
      background-color: #56bb6d !important;
    }
  }
}

.cross_icn_logout {
  position: absolute;
  right: 0;
  top: -20px;
  cursor: pointer;
  color: red;
  font-size: 10px !important;
}

figure.sideBarName {
  width: 60px;
  height: 60px;
  border-radius: 50px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50px;
  }
}

.pointer {
  cursor: pointer;
}

.org_detail {
  &.custom_tabs {
    .custom_tabs_links {
      margin-bottom: 25px;
    }
  }

  .coupon_card {
    cursor: pointer;
  }
}

.form_info {
  ul {
    &.gap_p {
      --gap_y: 20px;
    }

    li {
      width: calc(100% / 3);
      margin-bottom: 0;
      @include breakpoint(ipadLandsacpe){
      width: calc(100% / 2);

      }
      @include breakpoint(mobileSmall){
        width: 100%;
  
        }
      &.w_100 {
        width: 100%;
      }

      p {
        color: #a3a3a3;
        font-size: 12px;
        letter-spacing: 0.63px;
        margin-bottom: 2px;
        font-weight: 600;
        
      }

      h6 {
        font-size: 16px;
        word-break: break-word;
        @include breakpoint(mobile){
          font-size: 14px;
        }
      }

      figure {
        display: flex;
        width: 220px;
        height: 220px;
        background-color: #f9f9f9;
        @include breakpoint(mobile){
          width: 130px;
          height: 130px;
        }
        @include breakpoint(mobileSmall){
          width: 100px;
          height: 100px;
        }
        img {
         @include img_contain(cover);
          @include breakpoint(mobileSmall){
            max-width: 100%;
            background-color: #f9f9f9;
          }
        }
      }
    }
  }
}

.expore_box_single {
  height: 100%;
  overflow: hidden;
  border-radius: 20px;
  background-color: #f9f9f9;
  transition: $transition;
  padding-bottom: 20px;

  figure {
    width: 100%;
    aspect-ratio: 1 / 1;
    display: inline-flex;
    transition: $transition;
    margin-bottom: 15px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      transition: $transition;
    }
  }

  :is(h4, p) {
    padding-inline: 15px;
  }

  h4 {
    font-size: 12px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    gap: 15px;
  }

  p {
    font-size: 10px;
    font-weight: 500;
  }

  &:hover {
    cursor: pointer;
    background-color: $c_secondary;

    :is(h4, p) {
      color: $c_white;
    }

    figure {
      img {
        transform: scale(1.05);
      }
    }
  }
}

li.no_date {
  width: 100% !important;
  justify-content: center !important;
  font-weight: 600 !important;
  box-shadow: none !important;
  padding: 30px 0 !important;
  // min-height: calc(100vh - 450px);
}

p.no_date {
  width: 100% !important;
  justify-content: center !important;
  font-weight: 600 !important;
  box-shadow: none !important;
  padding: 30px 0 !important;
  text-align: center;
}

.box {
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 13px;

    li {
      position: relative;
      width: 48%;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      padding: 15px;
      border-radius: 4px;
      margin-bottom: 0;
      @include breakpoint(mobile){
        width: 100%;
      }

      svg {
        position: absolute;
        right: 4px;
        cursor: pointer;
        font-size: 17px;
        color: red;
        top: 4px;
      }
    }
  }
}




.pdf_cont {
  width: 100%;
  height: 100%;

  svg {
    color: red;
    position: absolute;
    right: 6px;
    top: 5px;
    font-size: 20px;
    cursor: pointer;
    z-index: 2;
  }
}

.react-pdf__Document {
  height: 100% !important;
  border: 1px solid black;
  overflow: scroll;
  overflow-x: hidden;
  position: absolute;
  top: 0;
}

.react-pdf__Document .react-pdf__Page {
  width: 100% !important;
  height: 400px !important;
  >div{
    display: none !important;
  }
}

.react-pdf__Page__canvas {
  width: 100% !important;
}

p.lable {
  color: #a3a3a3;
  font-size: 12px;
  letter-spacing: 0.63px;
  margin-bottom: 2px;
  font-weight: 600;
  &.mt_20{
    margin-top: 20px;
  }
}

.btn_group1 {
  @include d-flex(center, flex-end, 15px);
  flex: 1;
  flex-wrap: nowrap;
  @include breakpoint(mobileSmall){
    @include d-flex(center, flex-end, 8px);
    flex-wrap: nowrap;

  }
 

  .btn {
    min-width: 40px;
    min-height: 40px;
    padding: 2px;
    border-radius: 5px;
  }

  font-size: 12px;
  letter-spacing: 0.63px;
  margin-bottom: 2px;
  font-weight: 600;
}

.multi_select {
  .search-wrapper {
    border: none;
    padding: 0 !important;

    .chip {
      background-color: $c_secondary;
    }

    input {
      margin: 0;
    }
  }

  .optionListContainer {
    ul {
      li {
        font-size: 14px;
        font-weight: 500;

        &:not(:last-child) {
          margin-bottom: 0;
        }

        &.highlightOption {
          background-color: $c_secondary;
        }
      }
    }
  }
}

.pdf_divv_wrp {
  margin-bottom: 20px;
  .pdf_divv {
      width: calc(50% - var(--gap_x));
    height: 200px;
    @include breakpoint(mobileSmall){
      width: calc(100% - var(--gap_x));

    }
    .react-pdf__Document {
      border: 1px solid #CACACA;
      width: 100%;
      border-radius: 10px;
      overflow: auto;
      @include breakpoint(mobileSmall){
        width: 100% ;
      }
  }
  .pdf_cont{
    border: 1px solid #56bb6d;
    background-color: #fff;
    padding: 2px;
    transition: all 0.3s ease-in-out 0s;
    border-radius: 5px;
  }
}

// }
&:empty{
  display: none;
}
}


.fetch_currnt{
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  transition: $transition;
  h2{
    display: flex;
    align-items: center;
    font-size: 15px;
    svg{
      font-size: 17px;
      margin-right: 5px;
    }
    }
    &:hover{
      h2{
        color: #266fc7;
      }
    }
  }
.cms_pg_wrp{
  p{
    margin-bottom: 8px;
    line-height: normal;
  }
}
.filter_sort {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block: 0 25px;
  gap: 30px;
  .form {
    display: inline-flex;
    margin-top: 0;
    gap: 30px;
    @include breakpoint(ipad){
      flex-wrap: wrap;
      row-gap: 10px;
    }
   

      
    
    &_control {
      display: inline-flex;
      align-items: center;
      margin-block: 0;
      gap: 10px;
      @include breakpoint(mobile){
        flex-wrap: wrap;
        width: 100%;

      }
      label {
        font-size: 14px;
        font-weight: 800 !important;
        white-space: nowrap;
        margin: 0;
        @include breakpoint(ipad){
          min-width: 80px;
        }
        @include breakpoint(mobile){
          width: 100%;
        }
      }
      @include breakpoint(ipad){
      .MuiInputBase-root.MuiOutlinedInput-root {
        width: 48%;
    }
  }

      :is(input, select, .MuiSelect-select) {
        font-size: 14px;
        height: 40px !important;
        padding: 5px 10px;
        max-width: 150px;
        min-width: 150px;
        width: 100%;
        font-weight: 500;
        line-height: 40px;
        @include breakpoint(desktop){
          max-width: 120px;
          min-width: 120px;
        }
        @include breakpoint(mobile){
          min-width: unset;
          max-width: unset;
        }
      }
      .MuiSelect-select {
        padding: 0 25px 0 10px !important;
        border-color: #d3d3d3;
        line-height: 40px !important;
        ~.MuiSvgIcon-root {
          right: 4px;
          font-size: 20px;
        }
      }
    }
  }
}


.btn_link {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  color: #56BB6D;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s ease-in-out 0s;
  background: transparent;
  cursor: pointer;
  border: none;
  padding: 0;
  gap: 8px;
}
.c_danger {
  color: #D92D20;
}
.custom_tabs.org_detail{
  position: relative;
}
.bck_btn {
  &.pos_back{
    position: absolute;
    right: 0;
    top: 0px;
    @include breakpoint(ipadLandsacpe){
      top: 65px;
    }
    @include breakpoint(mobileSmall){
      top: 70px;
    }
  }
  .btn{
    min-width: 100px;
    padding: 8px 12px;
  }
}

h2.ctitle{
  margin-bottom: 20px;
  span{
    color: #56bb6d;
  }
}

.no_date_sc {
  text-align: center;
  max-width: 500px;
  margin-inline: auto;

  @include breakpoint(ipadLandsacpe) {
    max-width: 400px;
  }

  figure {
    display: inline-flex;
    align-items: center;

    @include breakpoint(ipadLandsacpe) {
      margin-bottom: 20px;
    }

    img {
      max-width: 125px;

      @include breakpoint(ipadLandsacpe) {
        max-width: 80px;
      }

      @include breakpoint(mobile) {
        max-width: 60px;
      }
    }
  }

  h2 {
    margin-bottom: 20px;

    @include breakpoint(ipadLandsacpe) {
      margin-bottom: 15px;
    }

    @include breakpoint(mobile) {
      font-size: 18px;
      font-weight: 800;
      margin-bottom: 10px;
    }
  }

  p {
    color: #707070;
    font-weight: 500;
    margin-bottom: 20px;

    @include breakpoint(mobile) {
      margin-bottom: 15px;
    }
  }

  .btn {
    min-width: 1px;
    font-size: 16px;
    min-height: 40px;

    @include breakpoint(mobile) {
      font-size: 14px;
    }
  }
}
.custom_tabs {
  .hd_4 p {
    margin-bottom: 5px;
  }
  .btn_flex {
    margin-top: 20px;
  }
}

.link_box {
      display: inline-flex;
      align-items: center;
      position: relative;

      @include breakpoint(mobile) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
      }

      p {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #f2f2f2;
        font-size: 14px;
        font-weight: 800;
        min-height: 40px;
        padding: 5px 12px;
        border-radius: 5px;
        cursor: pointer;
        margin: 0;
        gap: 10px;

        @include breakpoint(ipadLandsacpe) {
          min-height: 35px;
          padding: 5px 10px;
        }

        @include breakpoint(mobile) {
          border-radius: 0;
          justify-content: center;
          min-height: 40px;
          padding: 5px 15px;
          width: 100%;
          gap: 15px;
        }

        span {
          width: auto;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
        }

        figure {
          display: inline-flex;
          cursor: pointer;

          img {
            height: 26px;

            @include breakpoint(ipadLandsacpe) {
              height: 22px;
            }

            @include breakpoint(mobile) {
              height: 20px;
            }
          }
        }
      }

      >span {
        position: absolute;
        left: 50%;
        top: calc(100% + 15px);
        font-size: 13px;
        font-weight: 400;
        background-color: $c_white;
        white-space: nowrap;
        padding: 6px 10px;
        border-radius: 3px;
        transform: translateX(-50%);
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

        &::before {
          content: "";
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 100%;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 5px solid #fff;
        }
      }
    }

    .prfl_autocmplt {
      .MuiOutlinedInput-root input.MuiAutocomplete-input {
        padding-right: 55px !important;
        @include breakpoint(ipadLandsacpe) {
          padding-right: 40px !important;
        }
      }
      .MuiAutocomplete-endAdornment {

    top: auto;
        // top: calc(50% - 17.5px);
        right: 10px !important;
        @include breakpoint(ipadLandsacpe) {
          // top: calc(50% - 12px);
        }
        button {
          padding: 0;
          margin: 0;
          svg {
            color: $c_secondary;
            font-size: 35px;
            @include breakpoint(ipadLandsacpe) {
              font-size: 22px;
            }
            &[data-testid="CloseIcon"] {
              font-size: 24px;
              color: $c_red;
              @include breakpoint(ipadLandsacpe) {
                font-size: 20px;
              }
            }
          }
        }
      }
      &.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon {
        .MuiOutlinedInput-root {
          padding-right: 0 !important;
        }
        .MuiOutlinedInput-root input.MuiAutocomplete-input {
          padding-right: 90px !important;
          @include breakpoint(ipadLandsacpe) {
            padding-right: 65px !important;
          }
        }
      }
      fieldset.MuiOutlinedInput-notchedOutline {
        display: none;
      }
    }

    .mtmsk_ntsprt.u_spc {
    padding: 40px 0;
}

.modal {
  &_body {
    width: calc(100% - 30px);
    max-width: 530px;
    margin: auto;
    position: relative;
    @include breakpoint(desktop) {
      max-width: 450px;
    }
    @include breakpoint(mobile) {
      max-width: 400px;
    }
    .close {
      color: $c_black;
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 35px;
      cursor: pointer;
      transition: $transition;
      z-index: 1;
      @include breakpoint(desktop) {
        font-size: 25px;
      }
      &:hover {
        color: #FF0000;
      }
    }
  }
  &_title {
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 30px;
    @include breakpoint(ipadLandsacpe) {
      font-size: 22px;
    }
    @include breakpoint(mobile) {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
  &_content {
    background: $c_white;
    box-shadow: 0px 2px 56px #0000001a;
    border-radius: 10px;
    position: relative;
    text-align: center;
  }
}

.logout_modal {
  padding-top: 100px;

  .modal {
    &_title {
      text-align: center;
      margin-bottom: 20px;
      &.text_left {
        text-align: left;
        font-size: 20px;
        margin-bottom: 10px;
      }
    }
    .short_dsc {
      text-align: left;
      margin-bottom: 30px;
    }
    &_content {
      padding: 65px 40px;
      @include breakpoint(desktop) {
        padding: 55px 40px;
      }
      @include breakpoint(mobile) {
        padding: 55px 20px;
      }
      .btn_flex {
        justify-content: center;
        gap: 20px;
        @include breakpoint(mobile) {
          gap: 15px;
        }
        .btn {
          font-size: 16px;
          min-height: 45px;
          padding: 2px 20px;
          min-width: 100px;
          text-transform: uppercase;
          letter-spacing: 1px;
          @include breakpoint(mobile) {
            font-size: 14px;
            min-width: 80px;
          }
          &.br {
            color: $c_primary;
            background-color: $c_white;
            border-color: $c_primary;
          }
        }
      }
      .form_control
        :is(input, select, .MuiSelect-select, textarea):not(:focus) {
        border-color: #D3D3D3;
      }
    }
  }
}

.upload_icon{
  display: inline-flex;
  cursor: pointer;
  margin-bottom: 0 !important;

  &_holder {
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    img, .MuiCardMedia-root {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      border: 1px solid $c_white;
      object-fit: cover;
    }

    svg {
      width: 21px;
      height: 21px;
      border-radius: 50%;
      border: 1px solid $c_white;
      background: $c_secondary;
      color: $c_white;
      padding: 2px;
      margin-top: -10.5px;
      transition: $transition;
    }
  }

  &:hover {
    .upload_icon_holder {
      figure {
        border-color: $c_primary;
      }

      svg {
        background-color: $c_primary;
      }
    }
  }
}